@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons";
@import "~react-toastify/dist/ReactToastify.min.css";
@import "src/styles/index";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.text-blocks--wrapper {
  > * {
    min-width: 25%;
  }

  .text-block--wrapper {
    position: relative;

    // TODO: Maybe add this if menu bar cannot be distinguished from the surrounding.
    /* &:hover {
      @extend .border;
      @extend .border-light;
      
      .text-block--menu-bar > div {
        @extend .border;
        @extend .border-light;
      }
    } */

    .text-block--menu-bar {
      position: absolute;
      display: none;
      top: -2rem;
      right: 0;
      left: 0;

      > div {
        width: fit-content;
        pointer-events: auto;
      }
    }

    &:hover:not(:active) .text-block--menu-bar,
      // .text-block--menu-bar:hover:not(:active) {
    .text-block--menu-bar:hover {
      pointer-events: none;
      display: block;
    }

  }
}

.trash-button {
  &.is-drag-target {
    border-style: dashed;
  }
}

div.rmsc.form-control {
  padding: 0;
  border: 0;
  border-radius: 0;
  margin-left: 0;

  .dropdown-container {
    border-radius: 0;
    border-right: 0;
    height: 100%;

    .dropdown-heading {
      height: 100%;

      .dropdown-heading-value {
        text-align: start;
        color: darkgray;
      }
    }
  }
}
