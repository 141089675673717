.paused {
  animation-play-state: paused;
}

.animation-pop {
  animation: pop 0.5s linear 1;
}

.animation-max-height {
  transition: max-height 0.5s;
}

@keyframes pop {
  0% {
    opacity: 0
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1
  }
}